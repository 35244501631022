<template>
  <v-app theme="cool">
    <v-main style="height: 100vh">
      <nav-bar :navs="navigation" @updateFocus="focus = $event" />
      <v-container fluid style="height: 100%" class="bg-background">
        <v-expand-transition>
          <home-section v-if="focus == 'Home'" @updateFocus="focus = $event" />
        </v-expand-transition>
        <v-expand-transition>
          <resume-section v-if="focus == 'Resume'" />
        </v-expand-transition>
        <v-expand-transition>
          <projects-section v-if="focus == 'Projects'" />
        </v-expand-transition>
        <v-expand-transition>
          <contact-section v-if="focus == 'Contact'" />
        </v-expand-transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import ResumeSection from "./components/ResumeSection.vue";
import ContactSection from "./components/ContactSection.vue";
import HomeSection from "./components/HomeSection.vue";
import NavBar from "@/components/NavBar.vue";
import ProjectsSection from "./components/ProjectsSection.vue";

export default {
  name: "App",

  components: {
    ResumeSection,
    ContactSection,
    HomeSection,
    NavBar,
    ProjectsSection,
  },

  data: () => ({
    focus: "",
    navigation: ["Home", "Projects", "Contact"],
  }),
  mounted () {
    this.focus= "Home";
  },
};
</script>

<style lang="scss" scoped>
.x-row {
  height: 100%;
}
</style>
