<template>
  <v-row align="center" class="x-row lighten-5">
    <v-col v-for="project in projects" :key="project.name" align="center" cols="12" sm="12" md="12" lg="12" xl="12">
      <v-card outlined class="bg-background">
        <v-card-title
          class="
            text-primary text-h6 text-sm-h6 text-md-h5 text-lg-h4 text-xl-h4
            justify-center
          "
        >
          {{ project.name }}
        </v-card-title>
        <v-card-text class="text-white">
          {{ project.description }}
        </v-card-text>
        <v-card-actions class="justify-right">
          <v-btn
            v-for="action in project.actions"
            :key="action.name"
            variant="outlined"
            class="text-secondary rounded-pill ma-3"
            :href="action.url"
            >{{ action.name }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ProjectsSection",
  data() {
    return {
      projects: [

        {
          name: "Birdseye",
          description: "Created a web-app that allows a user to remotely rent and fly a drone that could be stationed anywhere in the world. "
            +"Utilized Dango for backend and page render, Vue for responsive pages, MySQL for database, Nginx for websever, and Stripe for payments.",
          actions: [{ name: "View Code", url: "https://github.com/CarterSScott/birdseye/tree/birdseye-carter", icon: "" }],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.x-row {
  height: 100%;
}
</style>