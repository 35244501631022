<template>
  <v-row align="center" class="x-row lighten-5">
    <v-col align="center">
      <embed full-frame/>
    </v-col>
  </v-row>
</template>

<script>
    export default {
        name: "ResumeSection",
        data() {
            return {
            }
        },
    }
</script>

<style lang="scss" scoped>
.x-row {
  height: 100%;
}
</style>