<template>
  <v-row align="center" justify="center" class="x-row">
    <v-col cols="12" sm="6" md="6" lg="6" xl="6" align="center">
      <div
        class="
          text-h5 text-sm-h5 text-md-h4 text-lg-h3 text-xl-h3 text-primary
          pa-10
        "
      >
        Contact Me
      </div>
      <v-container>
        <v-row>
          <v-col>
            <v-dialog v-model="success" width="100vw">
            <v-card class="bg-background">
              <v-card-title class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 text-primary
          pa-10 text-center">
                Message Recieved!
              </v-card-title>

              <v-card-text class="text-white">
                Hi there, thanks for sending me a message. I usually get back to
                people within 1-2 days.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="success = false">
                  Awesome!
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          </v-col>
        </v-row>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col>
              <v-text-field outlined label="Name" v-model="name"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                outlined
                label="Email"
                v-model="email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea outlined label="Body" v-model="body"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                block
                variant="outlined"
                class="text-secondary rounded-pill"
                @click="sendMessage()"
                >Send Message!</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
        <div class="text-center">
          
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
const axios = require("axios");

export default {
  name: "ContactSection",
  data() {
    return {
      name: "",
      email: "",
      body: "",
      success: false,
    };
  },
  methods: {
    sendMessage() {
      const vm = this;
      axios
        .post("api/email/new", {
          fullname: vm.name,
          email: vm.email,
          body: vm.body,
        })
        .then(() => {
          vm.success = true;
          vm.name = "";
          vm.email = "";
          vm.body = "";
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.x-row {
  height: 100%;
}
</style>