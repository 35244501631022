<template>
  <div class="bg-background">
    <v-row align="end" justify="center" class="bg-background">
      <v-btn
        icon
        color="bg-background"
        class="bg-background text-primary ma-2"
        flat
        v-for="link in links"
        :key="link.name"
        :href="link.url"
      >
        <v-icon>{{ link.icon }}</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "FooterSection",
  data() {
    return {
      links: [
        {
          name: "github",
          icon: "mdi-github",
          url: "https://github.com/CarterSScott",
        },
        {
          name: "linkedin",
          icon: "mdi-linkedin",
          url: "https://www.linkedin.com/in/carter-scott-b49a13a6/",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>