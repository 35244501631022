// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'



export default createVuetify({
  theme: {
    themes: {
      cool: {
        dark: false,
        colors: {
          background: '#222629',
          surface: '#FFFFFF',
          primary: '#86C232',
          'primary-darken-1': '#0B0C10',
          secondary: '#061892F',
          'secondary-darken-1': '#0B0C10',
          error: '#B00020',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
        }
      }
    },
  },
})
