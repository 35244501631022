<template>
  <div>
    <v-toolbar dark fixed app elevation="4" class="bg-background">
      <v-toolbar-title>
        <v-img
          :src="logoPath"
          @click="updateFocus('Home')"
          height="200px"
          width="100px"
          style="cursor: pointer"
        ></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="d-none d-sm-block">
        <v-toolbar-items>
          <v-btn v-for="nav in navs" :key="nav" @click="updateFocus(nav)">{{
            nav
          }}</v-btn>
          <v-btn link href="/pdf">Resume</v-btn>
        </v-toolbar-items>
      </div>
      <div class="d-sm-none">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </div>
    </v-toolbar>
    <v-navigation-drawer v-model="drawer" absolute bottom temporary class="bg-background">
      <v-list nav dense class="bg-background">
        <v-list-item v-for="nav in navs" :key="nav" @click="updateFocus(nav); drawer = !drawer;">
            <v-list-title>{{nav}}</v-list-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  props: {
    navs: {
      type: Array,
    },
  },
  data() {
    return {
      logoPath: require("../assets/logo.png"),
      drawer: false,
    };
  },
  methods: {
    updateFocus: function (value) {
      this.$emit("updateFocus", value);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
