<template>
  <v-row align="center" class="x-row lighten-5">
    <v-col align="center">
      <div class="text-h5 text-sm-h4 text-md-h4 text-lg-h3 text-xl-h3">
        Hello, my name is
        <span class="text-primary font-weight-bold">Carter Scott.</span>
      </div>
      <div class="text-h6 text-sm-h5 text-md-h5 text-lg-h4 text-xl-h4">
        I'm a
        <span class="typed-text text-primary text-decoration-underline">{{
          typeValue
        }}</span>
        <span class="cursor" :class="{ typing: typeStatus }">&nbsp;</span>
      </div>
      <v-container>
        <v-row justify="center">
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <v-btn
              block
              variant="outlined"
              class="text-secondary rounded-pill"
              @click="updateFocus('Projects')"
              >View Projects</v-btn
            >
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <v-btn
              block
              variant="outlined"
              class="text-secondary rounded-pill"
              @click="updateFocus('Contact')"
              >Get in Touch</v-btn
            >
          </v-col>
        </v-row>
        <footer-section />
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import FooterSection from "./FooterSection";

export default {
  name: "HomeSection",
  components: {
      FooterSection,
  },
  data() {
    return {
      typeValue: "",
      typeStatus: false,
      typeArray: [
        "Software Engineer.",
        "Creative Thinker.",
        "Lifelong Learner.",
      ],
      typingSpeed: 50,
      erasingSpeed: 25,
      newTextDelay: 2000,
      typeArrayIndex: 0,
      charIndex: 0,
    };
  },
  methods: {
    typeText() {
      if (this.charIndex < this.typeArray[this.typeArrayIndex].length) {
        if (!this.typeStatus) this.typeStatus = true;
        this.typeValue += this.typeArray[this.typeArrayIndex].charAt(
          this.charIndex
        );
        this.charIndex += 1;
        setTimeout(this.typeText, this.typingSpeed);
      } else {
        this.typeStatus = false;
        setTimeout(this.eraseText, this.newTextDelay);
      }
    },
    eraseText() {
      if (this.charIndex > 0) {
        if (!this.typeStatus) this.typeStatus = true;
        this.typeValue = this.typeArray[this.typeArrayIndex].substring(
          0,
          this.charIndex - 1
        );
        this.charIndex -= 1;
        setTimeout(this.eraseText, this.erasingSpeed);
      } else {
        this.typeStatus = false;
        this.typeArrayIndex += 1;
        if (this.typeArrayIndex >= this.typeArray.length)
          this.typeArrayIndex = 0;
        setTimeout(this.typeText, this.typingSpeed + 1000);
      }
    },
    updateFocus: function (value) {
      console.log(value);
      this.$emit("updateFocus", value);
    },
  },
  created() {
    setTimeout(this.typeText, this.newTextDelay + 200);
  },
};
</script>

<style lang="scss" scoped>
.x-row {
  height: 100%;
}
span.cursor {
  display: inline-block;
  margin-left: 3px;
  width: 4px;
  background-color: #fff;
  animation: cursorBlink 1s infinite;
}

span.cursor.typing {
  animation: none;
}

@keyframes cursorBlink {
  49% {
    background-color: #fff;
  }
  50% {
    background-color: transparent;
  }
  99% {
    background-color: transparent;
  }
}
</style>